import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, Transition as _Transition, withCtx as _withCtx, mergeProps as _mergeProps } from "vue";

import { computed } from 'vue';
var _hoisted_1 = {
  key: 0,
  "class": "z-10 absolute inset-0 flex justify-center items-center bg-inherit"
};
export default /*@__PURE__*/_defineComponent({
  __name: 'AppButton',
  props: {
    to: {},
    forceATag: {
      type: Boolean
    },
    level: {
      "default": 'primary'
    },
    size: {
      "default": 'medium'
    },
    icon: {
      "default": undefined
    },
    disabled: {
      type: Boolean
    },
    loading: {
      type: Boolean,
      "default": false
    }
  },
  setup: function setup(__props) {
    var props = __props;
    var computedDisabled = computed(function () {
      return props.disabled || props.loading;
    });
    return function (_ctx, _cache) {
      var _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon");
      var _component_DynLink = _resolveComponent("DynLink");
      return _openBlock(), _createBlock(_component_DynLink, _mergeProps({
        type: "button",
        "class": ["relative overflow-hidden rounded-md transition inline-block", [{
          'bg-blue-dark border-blue-dark text-white hover:bg-blue hover:border-blue': _ctx.level === 'primary',
          'bg-white border-blue-dark text-blue-dark hover:bg-gray-light': _ctx.level === 'secondary',
          'bg-transparent border-blue-dark text-blue-dark hover:bg-blue-hover': _ctx.level === 'transparent',
          'bg-red border-red text-white hover:bg-red-dark hover:border-red-dark': _ctx.level === 'danger',
          'bg-transparent border-red text-red hover:bg-red-light': _ctx.level === 'secondary-danger',
          'bg-blue-light border-blue-light text-blue-dark hover:bg-blue-hover hover:border-blue-hover': _ctx.level === 'edit'
        }, {
          'border px-4 py-2 text-xs font-semibold': _ctx.size === 'small',
          'border-1.5 px-4 py-3 text-xl font-bold': _ctx.size === 'medium'
        }, {
          'pointer-events-none opacity-75': _unref(computedDisabled)
        }]]
      }, {
        to: _ctx.to
      }, {
        "force-a-tag": _ctx.forceATag
      }), {
        "default": _withCtx(function () {
          return [_createElementVNode("span", {
            "class": _normalizeClass(["flex gap-x-2 items-center transition-opacity", {
              'opacity-0': _ctx.loading
            }])
          }, [_ctx.icon ? (_openBlock(), _createBlock(_component_FontAwesomeIcon, {
            key: 0,
            icon: _ctx.icon
          }, null, 8 /* PROPS */, ["icon"])) : _createCommentVNode("v-if", true), _renderSlot(_ctx.$slots, "default")], 2 /* CLASS */), _createVNode(_Transition, {
            name: "appear"
          }, {
            "default": _withCtx(function () {
              return [_ctx.loading ? (_openBlock(), _createElementBlock("span", _hoisted_1, [_createVNode(_component_FontAwesomeIcon, {
                icon: ['far', 'spinner-third'],
                spin: ""
              })])) : _createCommentVNode("v-if", true)];
            }),
            _: 1 /* STABLE */
          })];
        }),
        _: 3 /* FORWARDED */
      }, 16 /* FULL_PROPS */, ["class", "force-a-tag"]);
    };
  }
});